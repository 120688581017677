import { graphql } from "gatsby"
import { CharitiesPage } from "@pageTemplates"

export default CharitiesPage

export const pageQuery = graphql`
  query Charities {
    charitiesWeSupportJson {
      pageHeader {
        top
        bottom
      }
      featuredCharitiesCarousel {
        backgroundImage {
          src {
            childImageSharp {
              gatsbyImageData(width: 2000)
            }
            publicURL
          }
          alt
        }
        icon {
          src {
            childImageSharp {
              gatsbyImageData(width: 1000, placeholder: TRACED_SVG)
            }
          }
          alt
        }
        quote
        description
      }
      charitiesList {
        coloured
        values {
          image {
            src {
              childImageSharp {
                gatsbyImageData(width: 1000)
              }
            }
            alt
          }
          icon {
            src {
              childImageSharp {
                gatsbyImageData(height: 128, placeholder: TRACED_SVG)
              }
            }
            alt
          }
          title
          description
          descriptionNote
          linkLocation
        }
      }
      fields {
        slug
      }
    }
  }
`
